var rellax = new Rellax(".rellax");

document.addEventListener("DOMContentLoaded", () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }
});

function contactUs() {
  var dlog = document.getElementById("dlog");
  dlog.classList.add("is-active");
}

function closeModal() {
  var dlog = document.getElementById("dlog");
  dlog.classList.remove("is-active");
}

function sendTo() {
  var fullname = document.getElementById("fullname").value;
  var subject = document.getElementById("subject").value;
  var message = document.getElementById("message").value;

  window.location.href =
    "mailto:info@euknow.eu?subject=" +
    subject +
    "&body=" +
    fullname +
    "%0D%0A" +
    message.replace(/\r\n|\r|\n/g, "%0D%0A");

  closeModal();
}

const navbar = document.querySelector("#navbar");
const scrollspy = VanillaScrollspy(navbar);
scrollspy.init();
